.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
@media screen and (max-width: 786px) {
    .logo-f >img{
        width: 80%;
        align-self: center;
    
    }
    
}